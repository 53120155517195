// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/*-------------------------------------------------------------------------------------------------------------------
-------------------------------------------------------------------------------------------------------------------*/
/* lili */

:root {
  --ion-color-lili: linear-gradient(180deg, rgba(241, 90, 36, 1) 0%, rgba(255, 5, 0, 1) 100%);
  --ion-color-lili-rgb: 241, 90, 36;
  --ion-color-lili-contrast: #f8f8f8;
  --ion-color-lili-contrast-rgb: 248, 248, 248;
  --ion-color-lili-shade: #d44f20;
  --ion-color-lili-tint: #f26b3a;

  /* success */
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #f8f8f8;
  --ion-color-success-contrast-rgb: 248, 248, 248;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /* warning */
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #f8f8f8;
  --ion-color-warning-contrast-rgb: 248, 248, 248;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /* danger */
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #f8f8f8;
  --ion-color-danger-contrast-rgb: 248, 248, 248;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /* alarm*/
  .ion-color-alarm {
    --ion-color-base: #FF9100;
    --ion-color-base-rgb: 255, 145, 0;
    --ion-color-contrast: #FFFFFF;
    --ion-color-contrast-rgb: 255, 255, 255;
    --ion-color-shade: #E08100;
    --ion-color-tint: #FFA733;
    --ion-color-alarm-20: #FF910033;
  }
  --ion-color-alarm: #FF9100;
  --ion-color-alarm-20: #FF910033;
  /*grey*/
  .ion-color-grey {
    --ion-color-grey: #5c5c5c;
    --ion-color-grey-rgb: 92, 92, 92;
    --ion-color-grey-contrast: #ffffff;
    --ion-color-grey-contrast-rgb: 255, 255, 255;
    --ion-color-grey-shade: #515151;
    --ion-color-grey-tint: #6c6c6c;

  }


  /* white e black*/
  /**************WHITE*******************/
  --white: rgb(0, 0, 0);
  --white-80:rgba(0, 0, 0, 0.80);
  --white-70: rgba(0, 0, 0, 0.70);
  --white-50: rgba(0, 0, 0, 0.50);
  --white-20: rgba(0, 0, 0, 0.20);
  --white-10: rgba(0, 0, 0, 0.10);
  --white-0: rgba(0, 0, 0, 0.00);

  /****************BLACK*******************/

  --black: rgb(31, 31, 31);
  --black-70:rgba(31, 31, 31, 0.698);
  --ion-text-medium-dark:#464646;
  --input-background:#FFFFFF;
  --segment-button-bg:#292929;
}

/*-------------------------------------------------------------------------------------------------------------------
    Classi aggiuntive per la selezione del colore
-------------------------------------------------------------------------------------------------------------------*/
.ion-color-lili {
  --ion-color-base: var(--ion-color-lili);
  --ion-color-base-rgb: var(--ion-color-lili-rgb);
  --ion-color-contrast: var(--ion-color-lili-contrast);
  --ion-color-contrast-rgb: var(--ion-color-lili-contrast-rgb);
  --ion-color-shade: var(--ion-color-lili-shade);
  --ion-color-tint: var(--ion-color-lili-tint);

}

.ion-color-grey {
  --ion-color-base: var(--ion-color-grey);
  --ion-color-base-rgb: var(--ion-color-grey-rgb);
  --ion-color-contrast: var(--ion-color-grey-contrast);
  --ion-color-contrast-rgb: var(--ion-color-grey-contrast-rgb);
  --ion-color-shade: var(--ion-color-grey-shade);
  --ion-color-tint: var(--ion-color-grey-tint);
}

.my-background-lili {
  background-color: var(--ion-color-lili);
}

.my-item-lili {
  --background: var(--ion-color-step-50);
}

.my-selected-lili {

  font-size: 13px;

}

ion-tab-button.tab-selected.my-selected-desktop-lili:hover {
  --color-selected: #858585;
  /* Cambia colore al passaggio del mouse */
  background: #141414;
}

ion-tab-button.tab-button-desktop-lili.my-selected-desktop-lili:hover {
  --color-selected: #858585;
  /* Cambia colore al passaggio del mouse */
  background: #141414;
}

/*-------------------------------------------------------------------------------------------------------------------
    Scale di colore per l'iOS
-------------------------------------------------------------------------------------------------------------------*/
/*lili*/
.ios body[color-theme='lili'] {
  --ion-background-color: #EBEBEB;
  --ion-background-segment-button: #F5F5F5;
  --ion-background-color-rgb: linear-gradient(180deg, #fafafa, #f4f5f8);
  --ion-input-background: #F5F5F5;
  --ion-text-color: rgba(31, 31, 31, 1);
  --ion-text-color-rgb: 64, 62, 60;

  --ion-color-step-50: #f1f1f1;
  --ion-color-step-100: #e9e9e9;
  --ion-color-step-150: #e2e2e2;
  --ion-color-step-200: #dbdbda;
  --ion-color-step-250: #d3d3d3;
  --ion-color-step-300: #cccccc;
  --ion-color-step-350: #c5c5c4;
  --ion-color-step-400: #bdbdbd;
  --ion-color-step-450: #b6b6b5;
  --ion-color-step-500: #afafae;
  --ion-color-step-550: #a7a7a7;
  --ion-color-step-600: #a0a09f;
  --ion-color-step-650: #989898;
  --ion-color-step-700: #919190;
  --ion-color-step-750: #8a8a89;
  --ion-color-step-800: #828282;
  --ion-color-step-850: #7b7b7a;
  --ion-color-step-900: #747473;
  --ion-color-step-950: #6c6c6b;

  --ion-item-background: transparent;

  --ion-toolbar-background: var(--ion-input-background);

  --ion-tab-bar-background: var(--ion-input-background);

  --ion-card-background: #ffffff;
  --ion-text-medium-dark: #464646;
}

/*-------------------------------------------------------------------------------------------------------------------
  Classi aggiuntive per iOS
-------------------------------------------------------------------------------------------------------------------*/
ion-modal.lili {
  -webkit-backdrop-filter: blur(40px);
  backdrop-filter: blur(40px);
  --background: #fafafaff;
  --background-opacity: 0.5;
  --backdrop-opacity: 70% !important;
}

ion-segment.lili-controls {
  color: white;
  /* box-shadow: -5px -10px 29px rgba(0, 0, 0, 0.6); */
  --background: black;

}

ion-segment.lili-transparent {
  color: rgb(0, 0, 0);
  --background: transparent;

}

ion-segment-button.panel-desk-tab-lili {
  --background-checked: #1F1F1F;
  --border: 2px solid #1F1F1F;
  --indicator-color: transparent;
  --color-checked: rgba(255, 255, 255, 1);
  border: 2px solid;
  border-radius: 10px;
  height: 40px;

  ion-label {
    padding-left: 3px;
    padding-right: 3px;
    font-family: 'Roboto-regular';
    font-size: 13px;
  }

}

ion-segment-button.lili-default {
  --background-checked: linear-gradient(180deg, rgba(241, 90, 36, 1) 0%, rgba(255, 5, 0, 1) 100%);
  --indicator-color: transparent;
  --color-checked: #ffffff;

  ion-label {
    padding-left: 3px;
    padding-right: 3px;
    font-family: 'Roboto-regular';
    font-size: 13px;
  }

}

ion-segment.default-lili {
  color: white;
  background: rgba(241, 90, 36, 1);
}

ion-segment.default-lili {
  color: white;
  background: black;
  padding-left: 16px;
  border-radius: 0 !important;
  margin: 0 !important;
}

ion-segment-button.lili {
  --background-checked: linear-gradient(180deg, rgba(241, 90, 36, 1) 0%, rgba(255, 5, 0, 1) 100%);
  --indicator-color: transparent;
  --color-checked: #ffffff;

  ion-label {
    padding-left: 3px;
    padding-right: 3px;
    font-family: 'Roboto-regular';
    font-size: 13px;
  }

}

ion-segment-button.panel-lili {

  --indicator-color: black;
  --color-checked: #000000;

  ion-label {
    padding-left: 3px;
    padding-right: 3px;
    font-family: 'Roboto-regular';
    font-size: 13px;
  }

}

ion-segment-button.panel-lili.segment-button-checked {

  font-weight: bold;
}

ion-segment.lili {
  color: white;
  background: rgba(241, 90, 36, 1);
}

ion-segment.lili {
  color: white;
  background: black;
  padding-top: 8px;
  padding-left: 16px;
  padding-bottom: 8px;
  border-radius: 0 !important;
  margin: 0 !important;
}

/*-------------------------------------------------------------------------------------------------------------------
    Scale di colore per il material design
-------------------------------------------------------------------------------------------------------------------*/

/*lili*/
.md body[color-theme='lili'] {
  --ion-background-color: #EBEBEB;
  --ion-background-segment-button: #F5F5F5;
  --ion-background-color-rgb: linear-gradient(180deg, #fafafa, #f4f5f8);
  --ion-input-background: #F5F5F5;
  --ion-text-color: rgba(31, 31, 31, 1);
  --ion-text-color-rgb: 64, 62, 60;
  --ion-text-medium-dark: #464646;

  --ion-color-step-50: #f1f1f1;
  --ion-color-step-100: #e9e9e9;
  --ion-color-step-150: #e2e2e2;
  --ion-color-step-200: #dbdbda;
  --ion-color-step-250: #d3d3d3;
  --ion-color-step-300: #cccccc;
  --ion-color-step-350: #c5c5c4;
  --ion-color-step-400: #bdbdbd;
  --ion-color-step-450: #b6b6b5;
  --ion-color-step-500: #afafae;
  --ion-color-step-550: #a7a7a7;
  --ion-color-step-600: #a0a09f;
  --ion-color-step-650: #989898;
  --ion-color-step-700: #919190;
  --ion-color-step-750: #8a8a89;
  --ion-color-step-800: #828282;
  --ion-color-step-850: #7b7b7a;
  --ion-color-step-900: #747473;
  --ion-color-step-950: #6c6c6b;

  --ion-item-background: transparent;

  --ion-toolbar-background: var(--ion-input-background);

  --ion-tab-bar-background: var(--ion-input-background);


  --ion-card-background: #ffffff;
}


.card-device-lili {
  display: flex;
  height: 137px;
  max-height: 137px;
  padding: 16px;
  margin: 0;
  justify-content: space-between;
  align-items: flex-start;
  opacity: 1;
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0px 4px 20px 0px var(--segments-button-10, rgba(41, 41, 41, 0.10));
}

.panel-card-device {
  padding: 7px 7px;
  height: 220px;
  min-height: 238px;
  margin: 0;
  box-shadow: 0px 0px 20px #868686;
  border-radius: 10px;
  opacity: 1;
}

.card-device-thermostat {

  padding: 7px 7px;
  margin: 0;
  // background: transparent linear-gradient(180deg, #23272B 0%, #262A2E 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 20px #868686;
  border-radius: 10px;
  opacity: 1;
}

.card-device-security-area {
  padding: 7px 7px;
  height: 220px;
  min-height: 236px;
  margin: 0;
  box-shadow: 0px 0px 20px #868686;
  border-radius: 10px;
  opacity: 1;
}

.card-device-thermostat-desktop {
  min-height: 220px;
  height: 220px;
  padding: 7px 7px;
  margin: 0;
  box-shadow: 0px 0px 20px #868686;
  border-radius: 10px;
  opacity: 1;
}

.card-room {
  min-height: 80px;
  padding: 7px 11px;
  margin: 4px 5px;
  box-shadow: 0px 0px 20px #868686;
  border-radius: 10px;
  opacity: 1;
}

.card-time-window {

  padding: 7px 11px;
  margin: 4px 5px;
  box-shadow: 0px 0px 20px #868686;
  border-radius: 10px;
  opacity: 1;
}

.item-card {
  --background: var(--ion-card-background);
}

.img-lili {
  fill: var(--ion-color-lili);
}

.avatar-lili {
  fill: var(--ion-text-color);



}

.avatar-color-lili {
  fill: var(--ion-card-background);
  color: var(--ion-card-background);

}

.thumbnail-avatar-lili {
  border: 1px solid black;
  background: linear-gradient(162deg, rgba(31, 31, 31, 0.2) 11.66%, rgba(31, 31, 31, 0) 87.02%);
  border-radius: 50%;
  width: 36px;
  height: 36px;
  margin-right: 16px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.imgBorder-lili {
  border-radius: 50%;
  border: 2px solid rgba(31, 31, 31, 1);
  ;
}

.room-avatar-lili {
  fill: var(--ion-text-color);
}

.avatar-inactive-lili {
  fill: #fff;
}

.avatar-gradient-lili {
  fill: #f15a24;
}

.avatar-active-lili {
  fill: rgba(31, 31, 31, 1);
}

.info-active-lili {
  color: var(--ion-color-lili);
  font-family: 'Roboto-bold';
}

.info-inactive-lili {
  color: var(--ion-text-color);
  font-family: 'Roboto-regular';
}

.avatar-card-lili {
  fill: var(--ion-color-lili);
}

.avatar-device-lili {
  fill: var(--ion-text-color);
}

.icon-widget-lili {
  background: var(--ion-color-step-800);
}

//---------------colori dei testi ------------------//
ion-text.title-card-lili {
  color: rgba(31, 31, 31, 1);
}

ion-text.description-card-lili {
  color: rgba(163, 163, 163, 1);
}

ion-text.subtitle-color-lili {
  color: rgba(133, 133, 133, 1);
}

ion-text.gradient {
  background: -webkit-linear-gradient(#f15a24, #ff0500);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

ion-text.color-lili {
  color: black;
}

ion-text.section-header-title-lili {

  color: #1F1F1F;
}

ion-text.white-lili {

  color: #fff;
}

ion-text.dark-lili {

  color: #000000;
}

//--------------------------------//
ion-header::part(native) {
  border-bottom: transparent;

  .gradient {
    --background: rgb(250, 250, 250);
    --background: linear-gradient(180deg, rgba(250, 250, 250, 1) 0%, rgba(244, 245, 248, 1) 100%),
  }

}

.gradient-bg {
  color: #ffffff;
  border-radius: 15px;
}

.accordion-bg-lili {
  background: rgb(250, 250, 250);
  background: linear-gradient(180deg, rgba(250, 250, 250, 1) 0%, rgba(244, 245, 248, 1) 100%),
}


ion-content::part(native) {
  background-color: #f4f5f8;
}

ion-content::part(scroll) {
  scrollbar-color: #858585 transparent;
}


ion-content::part(scroll)::-webkit-scrollbar {
  width: 8px;
}

ion-content::part(scroll)::-webkit-scrollbar-track {
  background: transparent;
}

ion-content::part(scroll)::-webkit-scrollbar-thumb {
  border-radius: 4px;
}

ion-item {
  &.ion-input-color-lili {
    margin: 10px 0;
    --padding-start: 25px;
    border-radius: 10px;
    border: 1px solid rgba(31, 31, 31, 0.50);
    background: #F5F5F5;

    opacity: 1;
  }

  &.error {
    margin: 10px 0;
    --padding-start: 25px;
    background: transparent linear-gradient(180deg, #d7dadd 0%, #f1f1f3 100%) 0% 0% no-repeat padding-box;

    box-shadow: 5px 5px 20px #00000029;
    border: 1px solid red;

    border-radius: 10px;
    opacity: 1;
  }

  &.white-border-lili {
    margin: 10px 0;
    --padding-start: 25px;
    // background: transparent linear-gradient(180deg, #d7dadd 0%, #f1f1f3 100%) 0% 0% no-repeat padding-box;

    box-shadow: 5px 5px 20px #00000029;
    border: 1px solid #000000;
    border-radius: 10px;
    opacity: 1;
  }

  &.no-border-lili {
    margin: 10px 0;
    --padding-start: 25px;
    // background: transparent linear-gradient(180deg, #d7dadd 0%, #f1f1f3 100%) 0% 0% no-repeat padding-box;
    box-shadow: 5px 5px 20px #00000029;
    border-radius: 10px;
    border: 1px solid #c5c5c5;
    opacity: 1;
  }

}

sub.error {
  color: red;

}

sub.success {
  color: #2FDF75;

}

//-------------------------------colori icone-------------------------
ion-icon.lili {
  color: #262A2E;
}


ion-icon.white-text {
  color: rgb(255, 255, 255);
}

ion-icon.move-lili {

  background: rgba(41, 41, 41, 1);

}

//----------------------------------------------------------------------------
.searchbar-lili {
  --background: #fff;
  padding-left: 16px;
  padding-right: 16px;
  --box-shadow: none;

  font-family: "Roboto-regular";
}

div.colorGrey {
  color: grey;
}

.searchbar-background-lili {

  border-radius: 10px 10px 0px 0px;
  background: #F5F5F5;

  box-shadow: 0px 4px 20px 0px rgba(41, 41, 41, 0.10);

}

div.text-datetime-box-lili {
  width: 53px;
  height: 29px;
  background: linear-gradient(180deg, #fafafa 0%, #f4f5f8 100%) 0% 0% no-repeat padding-box;
  box-shadow: 16px 5px 27px rgba(255, 255, 255, 0.1607843137);
  border-radius: 8px;
  align-items: center;
  display: flex;

  justify-content: center;
  text-align: center;

}

.text-datetime-box-lili {
  width: 53px;
  height: 29px;
  background: linear-gradient(180deg, #fafafa 0%, #f4f5f8 100%) 0% 0% no-repeat padding-box;
  box-shadow: 16px 5px 27px rgba(255, 255, 255, 0.1607843137);
  border-radius: 8px;
  align-items: center;
  display: flex;

  justify-content: center;
  text-align: center;

}

hr.greyLine {
  height: 1px;
  border-width: 0;
  color: #acabab;
  background-color: #80808067;
}

sub.greyText {
  color: #80808067;
}

.menu-bg-lili {
  --background: #ffffff;
}

ion-menu::part(container) {
  border-radius: 0 10px 10px 0;
  box-shadow: 10px 10px 16px rgba(0, 0, 0, 0.18);
}

// ion-button.grey::part(native) {
//   background: rgb(250, 250, 250);
//   background: linear-gradient(180deg, rgba(250, 250, 250, 1) 0%, rgba(244, 245, 248, 1) 100%);
//   border-radius: 6px;
//   color: #ffffff;
//   font-family: 'Roboto-medium';
//   font-size: 16px;
// }


.unit-lili {
  display: flex;

  padding: 10px 16px;
  margin: 0;
  justify-content: space-between;
  align-items: flex-start;
  box-shadow: 5px 5px 40px #dadada80;
  border-radius: 10px;
  opacity: 1;
}


.global-lili {
  display: flex;
  height: 137px;
  max-height: 137px;
  padding: 16px;
  margin: 0;
  justify-content: space-between;
  align-items: flex-start;
  box-shadow: 5px 5px 40px #dadada80;
  border-radius: 10px;
  opacity: 1;

}

.technology-item-lili {
  background-color: #ffffff;
  box-shadow: 5px 15px 20px #dadada80;
  border-radius: 10px;
  // height: 76px;
}

ion-card.edit-lili {
  box-shadow: 0px 0px 40px #dadada80;

}

.color-digital-dro-lili {

  height: 110px;
  box-shadow: 16px 5px 27px #dadada80;
  background: linear-gradient(180deg, #fafafa 0%, #f4f5f8 100%) 0% 0% no-repeat padding-box;
  padding: 17px;
  align-items: center;
  /* Centra verticalmente il contenuto del box */
  justify-content: center;
  border-radius: 10px;
  // text-align: center;
}

ion-button::part(native) {

  --background-hover: #dadada80;
  // --background-hover-opacity: #dadada80;

}

ion-button.selected-lili::part(native) {

  max-height: 30px;
  border-radius: 10px;
  background: #403E3C;
  border: solid 2px #403E3C;
  color: #ffffff;
}

ion-button.unselected-lili::part(native) {

  max-height: 30px;
  border-radius: 10px;
  color: #403E3C;
  border: solid 2px #403E3C;
  background: transparent;
}

ion-button.selected-large-lili::part(native) {
  border-radius: 10px;
  background: #403E3C;
  border: solid 1.5px #403E3C;
  color: #ffffff;
}

ion-button.unselected-large-lili::part(native) {
  border-radius: 10px;
  color: #403E3C;
  border: solid 1.5px #403E3C;
  background: transparent;
}

.item-status-bg-lili {
  border-radius: 10px;
  max-width: 400px;
  min-width: 80%;
  background: #F5F5F5;

  /* Effect/Ion-button */
  box-shadow: 0px 4px 20px 0px rgba(41, 41, 41, 0.10);
}

.item-patrol-bg-lili {
  border-radius: 10px;
  max-width: 400px;
  min-width: 300px;
  background: #292929;

  /* Effect/Ion-button */
  box-shadow: 0px 4px 20px 0px rgba(41, 41, 41, 0.10);
}

.modal-header-bg-lili {
  background: var(--ion-background-color)
}

ion-button.selectDay-lili::part(native) {
  background-color: rgb(0, 0, 0);
  color: rgb(255, 255, 255);
  font-family: 'Roboto-bold';
}

ion-button.unselectDay-lili::part(native) {
  background-color: transparent;
  color: rgb(0, 0, 0);
  font-family: 'Roboto-bold';
  border: 1px solid rgb(0, 0, 0);
}

ion-button.selectDay-lili-lili::part(native) {
  background-color: rgb(0, 0, 0) !important;
  color: rgb(255, 255, 255) !important;

}

ion-button.unselectDay-lili-lili::part(native) {
  background-color: transparent;
  color: rgb(0, 0, 0);

  border: 1px solid rgb(0, 0, 0)
}

.wrapperClass-lili {
  height: 100%;
  border-radius: 0px 0px 10px 10px;
  background: rgba(255, 255, 255, 0.60);

  box-shadow: 0px 4px 20px 0px rgba(41, 41, 41, 0.10);
}

// classi per colorare i bottoni
ion-button.grey-controls-lili::part(native) {

  color: var(--ion-text-color);
  font-family: 'Roboto-medium';
  font-size: 16px;
  border-radius: 10px;
  border: 1.5px solid rgba(31, 31, 31, 0.10);
  background: rgba(255, 255, 255, 0.20);
  box-shadow: 0px 4px 20px 0px rgba(41, 41, 41, 0.10);

}

ion-button.grey-lili::part(native) {
  background: #ffffff;
  border-radius: 10px;
  color: var(--ion-text-color);
  font-family: 'Roboto-medium';
  font-size: 16px;
  border: 2px solid black;

}


ion-button.grey-no-border-lili::part(native) {
  background: #ffffff;
  border-radius: 10px;
  color: var(--ion-text-color);
  font-family: 'Roboto-medium';
  font-size: 16px;


}

ion-button.grey-outline-lili::part(native) {

  border: 1px solid white;
  background: #FFFFFF33;
  border-radius: 10px;


}

ion-button.black-lili::part(native) {

  background: linear-gradient(180deg, rgba(241, 90, 36, 1) 0%, rgba(255, 5, 0, 1) 100%);
  border-radius: 10px;
  color: #ffffff;
}

ion-button.solid-lili::part(native) {

  background: linear-gradient(180deg, rgba(241, 90, 36, 1) 0%, rgba(255, 5, 0, 1) 100%);
  border-radius: 10px;
  color: #ffffff;
}

ion-button.transparent-lili::part(native) {

  background: transparent;
  border-radius: 10px;
  border: 0.5px solid #ffffff;
  color: #ffffff;
}

// classi bottoni automation
ion-button.controls-grey-lili::part(native) {
  border-radius: 10px;
  border: 1.5px solid rgba(31, 31, 31, 0.10);
  background: rgba(255, 255, 255, 0.20);
  color: #000000;
  box-shadow: 0px 4px 20px 0px rgba(41, 41, 41, 0.10);

}

ion-button.controls-solid-lili::part(native) {

  background: linear-gradient(180deg, rgba(241, 90, 36, 1) 0%, rgba(255, 5, 0, 1) 100%);
  border-radius: 10px;
  color: #ffffff;
}



.ellipsis-lili {
  fill: white;
}

.header-backgroud-modal-lili {
  // background-color: white;

}

.background-lili {
  filter: brightness(0) invert(1);
  -webkit-filter: blur(15.5px);
}

.profile-gradient-lili {
  background: linear-gradient(to top, rgb(255 255 255 / 70%), rgba(0, 0, 0, 0));

}

.panelBackground-lili {
  background: #FFFFFFB2;
  box-shadow: 0px 4px 20px 0px var(--segments-button-10, rgba(41, 41, 41, 0.10));

}

.notifications-bg-lili {
  background-color: #FFFFFF;
}

.svg-no-border-lili {
  fill: black;
}

.svg-lili {
  fill: black;
  border: 1px solid black;
}

///////////---------------------------------------------///////////////
///
/// comandi per lo switch dei device

.outer-circle-switch-lili {
  position: relative;
  margin: 25%;
  width: 174px;
  height: 174px;
  border-radius: 50%;
  background: #EBEBEB;

}

.inner_circle_lili {
  background-image: linear-gradient(180deg, rgba(241, 90, 36, 1) 0%, rgba(255, 5, 0, 1) 100%);
  content: '';
  position: absolute;
  top: -10px;
  bottom: -10px;
  right: -10px;
  left: -10px;
  z-index: -1;
  border-radius: inherit;
  -moz-box-shadow: 0 0 75px #e7973a;
  -webkit-box-shadow: 0 0 75px #8d3b0b;
  box-shadow: 0 0 75px #be3106;
}

.off_circle_lili {
  background: #464646;
  content: '';
  position: absolute;
  top: -10px;
  bottom: -10px;
  right: -10px;
  left: -10px;
  z-index: -1;
  border-radius: inherit;
  -moz-box-shadow: 0 0 75px #000000;
  -webkit-box-shadow: 0 0 75px #000000;
  box-shadow: 0px 21px 49px 0px #FFF, 0px 11px 60px 0px rgba(38, 30, 30, 0.70);


}

.switchOff-lili {
  color: #464646;
  border-radius: 25px;
  margin-left: 25%;
}


///////////---------------------------------------------///////////////

///////////--------------------Classi per svg -------------------------///////////////
svg-icon.grey-lili {
  fill: rgba(70, 70, 70, 1);
}

svg-icon.orange-lili {
  fill: rgba(241, 90, 36, 1)
}

svg-icon.white-lili {
  fill: rgb(255, 255, 255);
}

svg-icon.white-svg-lili {
  fill: rgb(255, 255, 255);
}

svg-icon.default-lili {
  fill: rgba(31, 31, 31, 1);
}

//----------------------------- FAB BUTTON ----------------------------------------------------//


ion-fab-button.blackFab-lili::part(native) {
  background: rgb(0, 0, 0);
  box-shadow: 0 0 30px #0000002F;
  color: rgb(255, 255, 255);


}

ion-fab-button.greyFab-lili::part(native) {
  background: rgba(255, 255, 255, 1);
  color: rgb(26, 26, 26);
  width: 40px;
  height: 40px;
  box-shadow: 0px 0px 40px rgb(0 0 0 / 91%);


}

ion-fab-button.transparentFab-lili::part(native) {
  background: transparent;
  color: rgb(255, 255, 255);
  width: 40px;
  height: 40px;
}

ion-fab-button.dangerFab-lili::part(native) {
  background: rgb(207, 9, 9);
  color: white;
  width: 40px;
  height: 40px;
}



//--------------- colori degli item selected-----------------------
.selectedCard-lili {
  background: linear-gradient(180deg, rgba(35, 39, 43, 1), rgba(38, 42, 46, 1));
  color: white;

  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.2);
  --webkit-filter: blur(20px);
  opacity: 100% !important;
}

.unselectedCard-lili {

  background-color: white;
  color: black;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.2);
  --webkit-filter: blur(20px);
  opacity: 100% !important;
}

//-------------------------------------------
.weatherCard-lili {
  margin: 0;
  padding: 2.5px;
  width: 179px;
  height: 60px;
  border-radius: 10px;
  background: #FFF;

  box-shadow: 0px 4px 20px 0px rgba(41, 41, 41, 0.10);

  opacity: 1;
}

//--------------header desktop-----------------------

.tab-button-desktop-lili {
  border-radius: 10px;
  background: #FFF;

  /* Effect/Ion-button */
  box-shadow: 0px 4px 20px 0px rgba(41, 41, 41, 0.10);
  padding: 0px;
  margin: 0 0 0 8px;
  height: 60px;
  max-width: 100px;
}

.fade-lili {
  position: fixed;
  bottom: 0;
  margin: 0;
  width: 100%;
  height: 15vh;
  background: linear-gradient(to bottom, #fcfcfc00, #e4e4e4d9, #f0f8ff);
}

//stile per lo unitAvatar

.background-blur-lili {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  opacity: 16%;
  z-index: -1;
}

hr.lili {
  height: 0.5px;
  border-width: 0;
  color: #464646;
  background-color: #f6f6f62b;

}
