// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/*-------------------------------------------------------------------------------------------------------------------
    Colori e classi disponibili nel tema
-------------------------------------------------------------------------------------------------------------------*/
@import 'variables-lili.scss';
@import 'variables-dark.scss';
@import 'text-color-dark.scss';
@import 'text-color-lili.scss';
@import '../text//text-typography.scss';

/*-------------------------------------------------------------------------------------------------------------------
    Font
-------------------------------------------------------------------------------------------------------------------*/
:root {
  --ion-font-family: 'Roboto-regular';
  font-family: 'Roboto-regular';
}

ion-loading.lili-loading {
  .loading-wrapper {
    height: 100vh;
    width: 100vw;
    min-width: 100vw;
    min-height: 100vh;
    background-color: rgba(#fff, 0.5);
    color: #ffffff;
    font-size: 1rem;
    line-height: 1.5rem;
    text-align: center;
  }
}
