/*---------------------------------------------------------------TYPOGRAPHY------------------------------------------------------------------*/



// -------------------------------------------------------------------------------------------------------------
// -------------------------------------------------- TITLE ----------------------------------------------------
// -------------------------------------------------------------------------------------------------------------

.h1-roboto-bold-26 {
  font-family: 'Roboto-bold';
  font-size: 26px;


  display: block; //aggiunto per versione mobile controllare se si rompe qualcosa
}

.h2-roboto-medium-22 {

  font-family: 'Roboto-medium';
  font-size: 22px;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.h2-roboto-regular-22 {

  font-family: 'Roboto-regular';
  font-size: 22px;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.h3-roboto-medium-18 {

  font-family: 'Roboto-medium';
  font-size: 18px;

}

.h4-roboto-medium-16 {

  font-family: 'Roboto-medium';
  font-size: 16px;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  letter-spacing: 0.1px;

}

.title-header-roboto-bold-17 {

  font-family: 'Roboto-medium';
  font-size: 17px;

}

// -------------------------------------------------------------------------------------------------------------
// -------------------------------------------------- CARD ----------------------------------------------------
// -------------------------------------------------------------------------------------------------------------


.card-title-roboto-medium-16 {
  color: var(--ion-text-color);
  font-family: 'Roboto-medium';
  font-size: 16px;
}

.card-title-roboto-medium-13 {
  color: var(--ion-text-color);
  font-family: 'Roboto-medium';
  font-size: 13px;
}

.card-subtitle-roboto-medium-11 {

  font-family: 'Roboto-medium';
  font-size: 11px;
}


// -------------------------------------------------------------------------------------------------------------
// -------------------------------------------------- CONTENT ----------------------------------------------------
// -------------------------------------------------------------------------------------------------------------


.text-input-roboto-regular-16 {

  font-family: 'Roboto-regular';
  font-size: 16px;
  // white-space: nowrap;
  // overflow: hidden;
  // text-overflow: ellipsis;
}

.text-input-roboto-regular-16-mobile {

  font-family: 'Roboto-regular';
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-input-roboto-bold-16 {

  font-family: 'Roboto-bold';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.1px;
}

.caption-roboto-medium-13 {

  font-family: 'Roboto-medium';
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.1px;
}

.caption-roboto-bold-13 {

  font-family: 'Roboto-bold';
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.1px;
}

.caption-roboto-regular-13 {

  font-family: 'Roboto-regular';
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.1px;
  text-transform: uppercase;
}


// -------------------------------------------------------------------------------------------------------------
// -------------------------------------------------- ITEM / INPUT -------------------------------------------------
// -------------------------------------------------------------------------------------------------------------

.label-input-roboto-medium-13 {

  font-family: 'Roboto-medium';
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.1px;
}

.message-input-roboto-bold-11 {

  font-family: 'Roboto-bold';
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 1.5px;
}

.label-item-roboto-regular-13 {

  font-family: 'Roboto-regular';
  font-size: 13px;

}

.item-text-tab-bar-roboto-regular-11 {

  font-family: 'Roboto-regular';
  font-size: 11px;

}


.item-text-tab-bar-roboto-bold-11 {

  font-family: 'Roboto-bold';
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.2px;
}

// -------------------------------------------------------------------------------------------------------------
// -------------------------------------------------- BUTTON -------------------------------------------------
// -------------------------------------------------------------------------------------------------------------


.button-text-roboto-medium-upper-16 {

  font-family: 'Roboto-bold';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
}

.segment-button-roboto-medium-13 {

  font-family: 'Roboto-medium';
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.2px;
}

//classi creata perchè nei button di figma c'è, ma non nella typography
.button-text-small-roboto-regular-14 {

  font-family: 'Roboto-regular';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}

.roboto-regular-10 {

  font-family: 'Roboto-regular';
  font-size: 10px;
  font-style: normal;
  font-weight: 400;

}

// -------------------------------------------------------------------------------------------------------------
// -------------------------------------------------- LINKS -------------------------------------------------
// -------------------------------------------------------------------------------------------------------------

.primary-roboto-bold-16 {

  font-family: 'Roboto-bold';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.2px;
}

.secondary-roboto-bold-14 {

  font-family: 'Roboto-bold';
  font-size: 14px;
  text-decoration-line: underline;
  display: block;
}



// -------------------------------------------------------------------------------------------------------------
// -------------------------------------------------- NOTIFICATIONS -------------------------------------------------
// -------------------------------------------------------------------------------------------------------------

.notification-title-bold-13 {

  font-family: 'Roboto-bold';
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.2px;

}

.notification-title-regular-13 {

  font-family: 'Roboto-regular';
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.2px;
}

.notification-title-regular-upper-13 {

  font-family: 'Roboto-regular';
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.2px;
  text-transform: uppercase;
}

.notification-title-bold-upper-13 {

  font-family: 'Roboto-bold';
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.2px;
  text-transform: uppercase;
}

.notification-time-regular-upper-11 {

  font-family: 'Roboto-regular';
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.2px;
  text-transform: uppercase;
}

.notification-time-bold-upper-11 {

  font-family: 'Roboto-bold';
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.2px;
  text-transform: uppercase;
}

.sub-title-roboto-regular-12 {
  color: #5C5C5C;
  font-family: 'Roboto-regular';
  font-size: 12px;
  font-style: italic;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0.2px;
}

.notification-title-semibold-13 {
  font-family: 'Roboto-semibold';
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.2px;
}


// -------------------------------------------------------------------------------------------------------------
// -------------------------------------------------- ACCORDION -------------------------------------------------
// -------------------------------------------------------------------------------------------------------------
.accordion-description-semibold-12 {
  font-family: 'Roboto-semibold';
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.1px;
}

.accordion-description-regular-12 {
  font-family: 'Roboto-regular';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.1px;
}

//------------------------------------ CLASSI DI TESTO PER ELEMENTI non in libreria---------------------------------

ion-button {
  font-family: 'Roboto-medium';
  font-size: 13px;

}

.super-roboto-regular-30 {

  font-family: 'Roboto-regular';
  font-size: 30px;

}


.super-roboto-regular-45 {

  font-family: 'Roboto-regular';
  font-size: 45px;

}
