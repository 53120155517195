.medium-dark-dark{
    color:#5C5C5C;
}
.medium-dark{
    color:#858585;
}
.white-dark{
    color:#fff;
}

.primary-dark{
    color:#F15A24;
}
.primary-gradient-dark{
    background: -webkit-linear-gradient(#f15a24, #ff0500);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

}
.success-dark{
    color:#2FDF75;
}
.generic-status-dark{
    color:#C5C5C5;
}

.secondary-dark{
    color:#FF0500;
}
.text-light-dark{
    color:#A3A3A3;
}

