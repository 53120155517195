.medium-dark-lili{
    color:#464646;
}
.medium-lili{
    color:#5C5C5C;
}
.white-lili{
    color:#1F1F1F;
}

.primary-lili{
    color:#F15A24;
}
.primary-gradient-lili{
    background: -webkit-linear-gradient(#f15a24, #ff0500);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.success-lili{
    color:#1CA151;
}
.generic-status-lili{
    color:#525252;
}

.secondary-lili{
    color:#FF0500;
}
.text-light-lili{
    color:#858585;
}